<template>
  <q-page class="q-pa-sm">
    <div class="row">
      <div class="col-md-3 col-xs-6 q-pa-xs">
        <q-item class="bg-primary rounded-borders full-height text-white" rounded>
          <q-item-section avatar>
            <q-icon class="float-right" name="person" size="lg"></q-icon>
          </q-item-section>
          <q-item-section>
            <q-item-label class="text-h5">{{ statistics?.users?.total_count }}</q-item-label>
            <q-item-label caption class="text-grey">Всего пользователей</q-item-label>
          </q-item-section>
        </q-item>
      </div>

      <div class="col-md-3 col-xs-6 q-pa-xs">
        <q-item class="bg-secondary rounded-borders full-height text-white" rounded>
          <q-item-section avatar>
            <q-icon class="float-right" name="money" size="lg"></q-icon>
          </q-item-section>
          <q-item-section>
            <q-item-label class="text-h5">{{ statisticsMoneySum }} СП</q-item-label>
            <q-item-label caption class="text-white">Общий баланс пользователей</q-item-label>
          </q-item-section>
        </q-item>
      </div>

      <div class="col-md-6 col-xs-12 q-pa-xs">
        <q-item class="rounded-borders full-height text-white" rounded>
          <q-item-section>
            <q-input v-model="phone" clearable mask="+7 (###) ### ## ##" placeholder="+7 (___) ___-____"
              @keydown.enter.prevent="() => searchUser()">
              <template v-slot:prepend>
                <q-icon name="phone" />
              </template>

              <template v-slot:after>
                <q-btn color="accent" icon="search" label="Найти" type="submit" @click="() => searchUser()"></q-btn>
              </template>
            </q-input>
          </q-item-section>
        </q-item>
      </div>

      <div v-if="entity.account_no && phone" class="col-12 q-pa-xs">
        <q-item class="rounded-borders full-height shadow-5">
          <q-item-section avatar>
            <q-icon name="person" size="lg" />
          </q-item-section>

          <q-item-section>
            {{ $filters.numberToPhone(entity.phone) }}
          </q-item-section>

          <q-item-section side>
            <q-item-label class="text-h6 text-secondary">{{ balance }} СП</q-item-label>
          </q-item-section>
        </q-item>
      </div>

      <div v-if="entity.account_no && phone" class="col-12 q-pa-xs">
        <user-actions v-model="entity" class="rounded-borders full-height shadow-5 q-pa-md" />
      </div>

      <div v-if="entity.account_no && phone" class="col-12 q-pa-xs">
        <user-operations v-model="entity" />
      </div>

      <div v-if="entity.account_no && phone" class="col-12 q-pa-xs">
        <user-orders v-model="entity" />
      </div>

    </div>
  </q-page>
</template>

<script setup>
import { AdminApi } from '@/api/apis/admin-api.ts';
import { PartnerApi } from '@/api/apis/partner-api.ts';
import { StatisticsApi } from '@/api/apis/statistics-api.ts';
import UserActions from '@/components/users/actions';
import UserOperations from '@/components/users/operations';
import UserOrders from '@/components/users/orders';
import { phoneToNumber } from '@/helpers/filters/phone';
import { userStorage } from '@/lib/userStorage';
import millify from 'millify';
import { computed, onMounted, ref } from 'vue';

const entity = ref({});
const balance = ref(0);
const phone = ref(userStorage.lastUserPhone || '');

const getBalance = async () => {
  try {
    const { data } = await new PartnerApi().personControllerGetBalance(
      phoneToNumber(phone.value),
    );

    balance.value = data.dobroruble;
  } catch (e) {
    console.error(e);
  }
};

const searchUser = async () => {
  try {
    const { data } = await new AdminApi().personControllerGetUser(
      phoneToNumber(phone.value),
    );

    entity.value = data;

    userStorage.lastUserPhone = phone.value;

    getBalance();
  } catch (e) {
    console.error(e);
  }
};

const statistics = ref({});
const statisticsMoneySum = computed(() => {
  if (!statistics.value.funds) {
    return 0;
  }

  const currency = statistics.value.funds.find(({ currency }) => currency === 'dobroruble');
  if (!currency) return 0;

  return millify(currency.total, {
    precision: 2,
    decimalSeparator: ',',
  });
});
new StatisticsApi().statisticsControllerGetSummary().then(({ data }) => statistics.value = data);

onMounted(() => phone.value && searchUser());
</script>

<style></style>
