/* tslint:disable */
/* eslint-disable */
/**
 * API Documentation
 * Доброрубль JSON REST API
 *
 * OpenAPI spec version: 2.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '@/api';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { ForbiddenException } from '../models';
import { NotFoundException } from '../models';
import { OrderDto } from '../models';
import { OrdersResponseDto } from '../models';

/**
 * OrdersApi - axios parameter creator
 * @export
 */
export const OrdersApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Получить единичную покупку
     * @param {number} orderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    marketControllerGetOrder: async (orderId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'orderId' is not null or undefined
      if (orderId === null || orderId === undefined) {
        throw new RequiredError('orderId', 'Required parameter orderId was null or undefined when calling marketControllerGetOrder.');
      }
      const localVarPath = `/api/2.0/market/orders/{order_id}`
        .replace(`{${'order_id'}}`, encodeURIComponent(String(orderId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Получение истории покупок
     * @param {number} [skip] Пропустить n записей.
     * @param {number} [take] Количество записей в ответе.
     * @param {string} [start] Дата и время начала диапазона, отображаемого в ответе.
     * @param {string} [end] Дата и время окончания диапазона, отображаемого в ответе.
     * @param {string} [order] Сортировка элементов в ответе в порядке возрастания или убывания.
     * @param {string} [phone] Номер телефона пользователя в формате: 7XXXXXXXXXX
     * @param {number} [id] Уникальный идентификатор покупки
     * @param {string} [display] Фильтр, регулирующий отображение покупок       основанный на сроках действия (по умолчанию отображаются все)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    marketControllerGetOrders: async (skip?: number, take?: number, start?: string, end?: string, order?: string, phone?: string, id?: number, display?: string, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/2.0/market/orders`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      if (skip !== undefined) {
        localVarQueryParameter['skip'] = skip;
      }

      if (take !== undefined) {
        localVarQueryParameter['take'] = take;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      if (end !== undefined) {
        localVarQueryParameter['end'] = end;
      }

      if (order !== undefined) {
        localVarQueryParameter['order'] = order;
      }

      if (phone !== undefined) {
        localVarQueryParameter['phone'] = phone;
      }

      if (id !== undefined) {
        localVarQueryParameter['id'] = id;
      }

      if (display !== undefined) {
        localVarQueryParameter['display'] = display;
      }

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     * Для доступа необходима авторизация
     * @summary Получить историю покупок
     * @param {number} [skip] Пропустить n записей.
     * @param {number} [take] Количество записей в ответе.
     * @param {string} [start] Дата и время начала диапазона, отображаемого в ответе.
     * @param {string} [end] Дата и время окончания диапазона, отображаемого в ответе.
     * @param {string} [order] Сортировка элементов в ответе в порядке возрастания или убывания.
     * @param {number} [id] Уникальный идентификатор покупки
     * @param {string} [display] Фильтр, регулирующий отображение покупок       основанный на сроках действия (по умолчанию отображаются все)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    marketControllerGetUserOrders: async (skip?: number, take?: number, start?: string, end?: string, order?: string, id?: number, display?: string, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/2.0/market/purchases`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      if (skip !== undefined) {
        localVarQueryParameter['skip'] = skip;
      }

      if (take !== undefined) {
        localVarQueryParameter['take'] = take;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      if (end !== undefined) {
        localVarQueryParameter['end'] = end;
      }

      if (order !== undefined) {
        localVarQueryParameter['order'] = order;
      }

      if (id !== undefined) {
        localVarQueryParameter['id'] = id;
      }

      if (display !== undefined) {
        localVarQueryParameter['display'] = display;
      }

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * OrdersApi - functional programming interface
 * @export
 */
export const OrdersApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Получить единичную покупку
     * @param {number} orderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async marketControllerGetOrder(orderId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderDto>> {
      const localVarAxiosArgs = await OrdersApiAxiosParamCreator(configuration)
        .marketControllerGetOrder(orderId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Получение истории покупок
     * @param {number} [skip] Пропустить n записей.
     * @param {number} [take] Количество записей в ответе.
     * @param {string} [start] Дата и время начала диапазона, отображаемого в ответе.
     * @param {string} [end] Дата и время окончания диапазона, отображаемого в ответе.
     * @param {string} [order] Сортировка элементов в ответе в порядке возрастания или убывания.
     * @param {string} [phone] Номер телефона пользователя в формате: 7XXXXXXXXXX
     * @param {number} [id] Уникальный идентификатор покупки
     * @param {string} [display] Фильтр, регулирующий отображение покупок       основанный на сроках действия (по умолчанию отображаются все)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async marketControllerGetOrders(skip?: number, take?: number, start?: string, end?: string, order?: string, phone?: string, id?: number, display?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrdersResponseDto>> {
      const localVarAxiosArgs = await OrdersApiAxiosParamCreator(configuration)
        .marketControllerGetOrders(skip, take, start, end, order, phone, id, display, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Для доступа необходима авторизация
     * @summary Получить историю покупок
     * @param {number} [skip] Пропустить n записей.
     * @param {number} [take] Количество записей в ответе.
     * @param {string} [start] Дата и время начала диапазона, отображаемого в ответе.
     * @param {string} [end] Дата и время окончания диапазона, отображаемого в ответе.
     * @param {string} [order] Сортировка элементов в ответе в порядке возрастания или убывания.
     * @param {number} [id] Уникальный идентификатор покупки
     * @param {string} [display] Фильтр, регулирующий отображение покупок       основанный на сроках действия (по умолчанию отображаются все)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async marketControllerGetUserOrders(skip?: number, take?: number, start?: string, end?: string, order?: string, id?: number, display?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrdersResponseDto>> {
      const localVarAxiosArgs = await OrdersApiAxiosParamCreator(configuration)
        .marketControllerGetUserOrders(skip, take, start, end, order, id, display, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * OrdersApi - factory interface
 * @export
 */
export const OrdersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Получить единичную покупку
     * @param {number} orderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    marketControllerGetOrder(orderId: number, options?: any): AxiosPromise<OrderDto> {
      return OrdersApiFp(configuration)
        .marketControllerGetOrder(orderId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Получение истории покупок
     * @param {number} [skip] Пропустить n записей.
     * @param {number} [take] Количество записей в ответе.
     * @param {string} [start] Дата и время начала диапазона, отображаемого в ответе.
     * @param {string} [end] Дата и время окончания диапазона, отображаемого в ответе.
     * @param {string} [order] Сортировка элементов в ответе в порядке возрастания или убывания.
     * @param {string} [phone] Номер телефона пользователя в формате: 7XXXXXXXXXX
     * @param {number} [id] Уникальный идентификатор покупки
     * @param {string} [display] Фильтр, регулирующий отображение покупок       основанный на сроках действия (по умолчанию отображаются все)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    marketControllerGetOrders(skip?: number, take?: number, start?: string, end?: string, order?: string, phone?: string, id?: number, display?: string, options?: any): AxiosPromise<OrdersResponseDto> {
      return OrdersApiFp(configuration)
        .marketControllerGetOrders(skip, take, start, end, order, phone, id, display, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Для доступа необходима авторизация
     * @summary Получить историю покупок
     * @param {number} [skip] Пропустить n записей.
     * @param {number} [take] Количество записей в ответе.
     * @param {string} [start] Дата и время начала диапазона, отображаемого в ответе.
     * @param {string} [end] Дата и время окончания диапазона, отображаемого в ответе.
     * @param {string} [order] Сортировка элементов в ответе в порядке возрастания или убывания.
     * @param {number} [id] Уникальный идентификатор покупки
     * @param {string} [display] Фильтр, регулирующий отображение покупок       основанный на сроках действия (по умолчанию отображаются все)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    marketControllerGetUserOrders(skip?: number, take?: number, start?: string, end?: string, order?: string, id?: number, display?: string, options?: any): AxiosPromise<OrdersResponseDto> {
      return OrdersApiFp(configuration)
        .marketControllerGetUserOrders(skip, take, start, end, order, id, display, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * OrdersApi - object-oriented interface
 * @export
 * @class OrdersApi
 * @extends {BaseAPI}
 */
export class OrdersApi extends BaseAPI {
  /**
   *
   * @summary Получить единичную покупку
   * @param {number} orderId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrdersApi
   */
  public marketControllerGetOrder(orderId: number, options?: any) {
    return OrdersApiFp(this.configuration)
      .marketControllerGetOrder(orderId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Получение истории покупок
   * @param {number} [skip] Пропустить n записей.
   * @param {number} [take] Количество записей в ответе.
   * @param {string} [start] Дата и время начала диапазона, отображаемого в ответе.
   * @param {string} [end] Дата и время окончания диапазона, отображаемого в ответе.
   * @param {string} [order] Сортировка элементов в ответе в порядке возрастания или убывания.
   * @param {string} [phone] Номер телефона пользователя в формате: 7XXXXXXXXXX
   * @param {number} [id] Уникальный идентификатор покупки
   * @param {string} [display] Фильтр, регулирующий отображение покупок       основанный на сроках действия (по умолчанию отображаются все)
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrdersApi
   */
  public marketControllerGetOrders(skip?: number, take?: number, start?: string, end?: string, order?: string, phone?: string, id?: number, display?: string, options?: any) {
    return OrdersApiFp(this.configuration)
      .marketControllerGetOrders(skip, take, start, end, order, phone, id, display, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Для доступа необходима авторизация
   * @summary Получить историю покупок
   * @param {number} [skip] Пропустить n записей.
   * @param {number} [take] Количество записей в ответе.
   * @param {string} [start] Дата и время начала диапазона, отображаемого в ответе.
   * @param {string} [end] Дата и время окончания диапазона, отображаемого в ответе.
   * @param {string} [order] Сортировка элементов в ответе в порядке возрастания или убывания.
   * @param {number} [id] Уникальный идентификатор покупки
   * @param {string} [display] Фильтр, регулирующий отображение покупок       основанный на сроках действия (по умолчанию отображаются все)
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrdersApi
   */
  public marketControllerGetUserOrders(skip?: number, take?: number, start?: string, end?: string, order?: string, id?: number, display?: string, options?: any) {
    return OrdersApiFp(this.configuration)
      .marketControllerGetUserOrders(skip, take, start, end, order, id, display, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
