/* tslint:disable */
/* eslint-disable */
/**
 * API Documentation
 * Доброрубль JSON REST API
 *
 * OpenAPI spec version: 2.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '@/api';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { CreateChargePayloadDto } from '../models';
import { CreateChargeResponseDto } from '../models';

/**
 * PaymentsApi - axios parameter creator
 * @export
 */
export const PaymentsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Платеж может быть авторизован автоматически,       либо через подтверждение стороны с которой взимаются средства       (в настоящее время <b>всегда</b> авторизуется автоматически)
     * @summary Списать средства с участника системы
     * @param {CreateChargePayloadDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transactionControllerMakeCharge: async (body: CreateChargePayloadDto, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling transactionControllerMakeCharge.');
      }
      const localVarPath = `/api/2.0/payments/charge`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      localVarHeaderParameter['Content-Type'] = 'application/json';

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization = (typeof body !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || '');

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PaymentsApi - functional programming interface
 * @export
 */
export const PaymentsApiFp = function (configuration?: Configuration) {
  return {
    /**
     * Платеж может быть авторизован автоматически,       либо через подтверждение стороны с которой взимаются средства       (в настоящее время <b>всегда</b> авторизуется автоматически)
     * @summary Списать средства с участника системы
     * @param {CreateChargePayloadDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async transactionControllerMakeCharge(body: CreateChargePayloadDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateChargeResponseDto>> {
      const localVarAxiosArgs = await PaymentsApiAxiosParamCreator(configuration)
        .transactionControllerMakeCharge(body, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * PaymentsApi - factory interface
 * @export
 */
export const PaymentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     * Платеж может быть авторизован автоматически,       либо через подтверждение стороны с которой взимаются средства       (в настоящее время <b>всегда</b> авторизуется автоматически)
     * @summary Списать средства с участника системы
     * @param {CreateChargePayloadDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    transactionControllerMakeCharge(body: CreateChargePayloadDto, options?: any): AxiosPromise<CreateChargeResponseDto> {
      return PaymentsApiFp(configuration)
        .transactionControllerMakeCharge(body, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * PaymentsApi - object-oriented interface
 * @export
 * @class PaymentsApi
 * @extends {BaseAPI}
 */
export class PaymentsApi extends BaseAPI {
  /**
   * Платеж может быть авторизован автоматически,       либо через подтверждение стороны с которой взимаются средства       (в настоящее время <b>всегда</b> авторизуется автоматически)
   * @summary Списать средства с участника системы
   * @param {CreateChargePayloadDto} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentsApi
   */
  public transactionControllerMakeCharge(body: CreateChargePayloadDto, options?: any) {
    return PaymentsApiFp(this.configuration)
      .transactionControllerMakeCharge(body, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
