<template>
  <q-table v-model:pagination="pagination" :loading="loading" :rows="rows" :rows-per-page-options="[3, 10, 50, 100, 1000]"
    class="full-width" separator="none" @request="onRequest">
    <template v-slot:top></template>
    <template v-slot:header>
      <div class="text-overline q-px-md">
        Покупки пользователя
        <q-btn class="q-mx-sm float-right" color="secondary" icon="refresh" label="" size="sm" @click="() => onRequest()" />

        <q-tabs v-model="statusFilter" align="justify" dense>
          <q-tab label="Активные" name="active" />
          <q-tab label="Использованные" name="used" />
          <q-tab label="Просроченные" name="expired" />
        </q-tabs>

      </div>
    </template>

    <template v-slot:body="{ row }">
      <div class="full-width q-ma-xs q-pa-xs">
        <q-item class="shadow-2 rounded-borders">
          <q-item-section avatar>
            <q-img :src=row.logo_url />
          </q-item-section>

          <q-item-section>
            <q-item-label>
              <q-expansion-item v-model="row.qrRendered" :label="row.header" class="text-center" dense
                @after-show="() => renderQR('qr-order-' + row.order_id, row.coupon)">
                <div class="text-h6">{{ row.coupon }}</div>
                <canvas :id="'qr-order-' + row.order_id" height="100" width="100" />
              </q-expansion-item>
            </q-item-label>
            <q-item-label caption>{{ $filters.toHumanDateTime(row.created_at) }}</q-item-label>
          </q-item-section>

          <q-item-section class="text-left" side>
            <q-item-label v-if="row.used">
              Использован
              <q-item-label caption>{{
                $filters.toHumanDateTime(row.activation_time)
              }}
              </q-item-label>
            </q-item-label>

            <q-item-label v-if="!row.used" caption>
              Не использован
            </q-item-label>

          </q-item-section>

          <q-item-section side style="width: 200px;">
            <q-item-label class="text-h6">
              {{ row.price }} СП
            </q-item-label>
          </q-item-section>
        </q-item>

      </div>
    </template>

  </q-table>
</template>

<script setup>
import { OrdersApi } from '@/api/apis/orders-api.ts';
import QRCode from 'qrcode';
import { debounce } from 'quasar';
import {
  computed, defineEmits, defineProps, onMounted, ref, watch,
} from 'vue';

const props = defineProps(['modelValue']);
const emit = defineEmits(['update:modelValue', 'updated']);

const entity = computed(() => props.modelValue);

const rows = ref([]);
const statusFilter = ref('active');
const loading = ref(false);
const pagination = ref({
  sortBy: 'datetime',
  descending: true,
  page: 1,
  rowsPerPage: 3,
  rowsNumber: 10,
});

async function onRequest(params) {
  if (!entity.value?.account_no) {
    return;
  }

  params = params || {
    pagination: pagination.value,
  };

  const {
    page,
    rowsPerPage,
    sortBy,
    descending,
  } = params.pagination;

  loading.value = true;

  const response = await new OrdersApi().marketControllerGetOrders(
    (page - 1) * rowsPerPage,
    rowsPerPage,
    undefined,
    undefined,
    undefined,
    entity.value.phone,
    undefined,
    statusFilter.value,
  );
  const { data } = response.data;
  pagination.value.rowsNumber = response.data.count;

  rows.value = data.map((row) => ({
    ...row,
    qrRendered: false,
  }));

  pagination.value.page = page;
  pagination.value.rowsPerPage = rowsPerPage;
  pagination.value.sortBy = sortBy;
  pagination.value.descending = descending;

  loading.value = false;
}

const onRequestDebounced = debounce(onRequest, 500);
onMounted(() => onRequestDebounced());
watch(() => statusFilter.value, () => onRequestDebounced());
watch(() => entity.value, () => onRequestDebounced());

const renderQR = (nodeId, data) => {
  QRCode.toCanvas(document.getElementById(nodeId),
    data,
    {
      width: 100,
      height: 100,
    },
    (error) => {
      if (error) console.error(error);
    });
};

</script>
