/* tslint:disable */
/* eslint-disable */
/**
 * API Documentation
 * Доброрубль JSON REST API
 *
 * OpenAPI spec version: 2.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '@/api';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { ForbiddenException } from '../models';
import { GetSummaryStatisticsResponseDto } from '../models';

/**
 * StatisticsApi - axios parameter creator
 * @export
 */
export const StatisticsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Файл будет содержать три колонки - Code, Order date, Activation date.
     * @summary Скачать список промо-кодов организации в формате .csv
     * @param {number} organizationNo
     * @param {number} [accountNo] Уникальный идентификатор учетной записи
     * @param {string} [start] Дата и время начала диапазона, отображаемого в ответе.
     * @param {string} [end] Дата и время окончания диапазона, отображаемого в ответе.
     * @param {string} [sortBy] Сортировка купонов в ответе по указанному значению, например купону, времени создания или времени активации.
     * @param {string} [sortOrder] Сортировка элементов в ответе в порядке возрастания или убывания.
     * @param {number} [offerId] Идентификатор предложения
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationControllerGetCouponActivations: async (organizationNo: number, accountNo?: number, start?: string, end?: string, sortBy?: string, sortOrder?: string, offerId?: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'organizationNo' is not null or undefined
      if (organizationNo === null || organizationNo === undefined) {
        throw new RequiredError('organizationNo', 'Required parameter organizationNo was null or undefined when calling organizationControllerGetCouponActivations.');
      }
      const localVarPath = `/api/2.0/organization/{organization_no}/codes.csv`
        .replace(`{${'organization_no'}}`, encodeURIComponent(String(organizationNo)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      if (accountNo !== undefined) {
        localVarQueryParameter['account_no'] = accountNo;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      if (end !== undefined) {
        localVarQueryParameter['end'] = end;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter['sort_by'] = sortBy;
      }

      if (sortOrder !== undefined) {
        localVarQueryParameter['sort_order'] = sortOrder;
      }

      if (offerId !== undefined) {
        localVarQueryParameter['offer_id'] = offerId;
      }

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Получить сводную статистику о пользователях
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statisticsControllerGetSummary: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/2.0/statistics/summary`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * StatisticsApi - functional programming interface
 * @export
 */
export const StatisticsApiFp = function (configuration?: Configuration) {
  return {
    /**
     * Файл будет содержать три колонки - Code, Order date, Activation date.
     * @summary Скачать список промо-кодов организации в формате .csv
     * @param {number} organizationNo
     * @param {number} [accountNo] Уникальный идентификатор учетной записи
     * @param {string} [start] Дата и время начала диапазона, отображаемого в ответе.
     * @param {string} [end] Дата и время окончания диапазона, отображаемого в ответе.
     * @param {string} [sortBy] Сортировка купонов в ответе по указанному значению, например купону, времени создания или времени активации.
     * @param {string} [sortOrder] Сортировка элементов в ответе в порядке возрастания или убывания.
     * @param {number} [offerId] Идентификатор предложения
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationControllerGetCouponActivations(organizationNo: number, accountNo?: number, start?: string, end?: string, sortBy?: string, sortOrder?: string, offerId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await StatisticsApiAxiosParamCreator(configuration)
        .organizationControllerGetCouponActivations(organizationNo, accountNo, start, end, sortBy, sortOrder, offerId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Получить сводную статистику о пользователях
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async statisticsControllerGetSummary(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSummaryStatisticsResponseDto>> {
      const localVarAxiosArgs = await StatisticsApiAxiosParamCreator(configuration)
        .statisticsControllerGetSummary(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * StatisticsApi - factory interface
 * @export
 */
export const StatisticsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     * Файл будет содержать три колонки - Code, Order date, Activation date.
     * @summary Скачать список промо-кодов организации в формате .csv
     * @param {number} organizationNo
     * @param {number} [accountNo] Уникальный идентификатор учетной записи
     * @param {string} [start] Дата и время начала диапазона, отображаемого в ответе.
     * @param {string} [end] Дата и время окончания диапазона, отображаемого в ответе.
     * @param {string} [sortBy] Сортировка купонов в ответе по указанному значению, например купону, времени создания или времени активации.
     * @param {string} [sortOrder] Сортировка элементов в ответе в порядке возрастания или убывания.
     * @param {number} [offerId] Идентификатор предложения
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationControllerGetCouponActivations(organizationNo: number, accountNo?: number, start?: string, end?: string, sortBy?: string, sortOrder?: string, offerId?: number, options?: any): AxiosPromise<void> {
      return StatisticsApiFp(configuration)
        .organizationControllerGetCouponActivations(organizationNo, accountNo, start, end, sortBy, sortOrder, offerId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Получить сводную статистику о пользователях
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statisticsControllerGetSummary(options?: any): AxiosPromise<GetSummaryStatisticsResponseDto> {
      return StatisticsApiFp(configuration)
        .statisticsControllerGetSummary(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * StatisticsApi - object-oriented interface
 * @export
 * @class StatisticsApi
 * @extends {BaseAPI}
 */
export class StatisticsApi extends BaseAPI {
  /**
   * Файл будет содержать три колонки - Code, Order date, Activation date.
   * @summary Скачать список промо-кодов организации в формате .csv
   * @param {number} organizationNo
   * @param {number} [accountNo] Уникальный идентификатор учетной записи
   * @param {string} [start] Дата и время начала диапазона, отображаемого в ответе.
   * @param {string} [end] Дата и время окончания диапазона, отображаемого в ответе.
   * @param {string} [sortBy] Сортировка купонов в ответе по указанному значению, например купону, времени создания или времени активации.
   * @param {string} [sortOrder] Сортировка элементов в ответе в порядке возрастания или убывания.
   * @param {number} [offerId] Идентификатор предложения
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatisticsApi
   */
  public organizationControllerGetCouponActivations(organizationNo: number, accountNo?: number, start?: string, end?: string, sortBy?: string, sortOrder?: string, offerId?: number, options?: any) {
    return StatisticsApiFp(this.configuration)
      .organizationControllerGetCouponActivations(organizationNo, accountNo, start, end, sortBy, sortOrder, offerId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Получить сводную статистику о пользователях
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatisticsApi
   */
  public statisticsControllerGetSummary(options?: any) {
    return StatisticsApiFp(this.configuration)
      .statisticsControllerGetSummary(options)
      .then((request) => request(this.axios, this.basePath));
  }
}
